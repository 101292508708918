<div
  class="ap-w-full lg:ap-max-h-[505px] ap-h-fit ap-flex ap-flex-col ap-gap-3"
  [ngClass]="{
    '!ap-max-h-[calc(100vh-162px)]': canAddNewField,
    'ap-overflow-y-auto': !canAddNewField
  }"
>
  <div
    class="ap-flex ap-flex-row ap-justify-between ap-items-center ap-px-[30px] ap-py-[10px] ap-sticky ap-top-0 ap-bg-white"
  >
    <div class="ap-text-[15px] ap-text-black ap-font-inter ap-font-semibold">
      Primary Keys
    </div>
    <ng-container *ngIf="canAddNewField">
      <ap-button
        btnStyle="basic"
        btnColor="primary"
        btnSize="small"
        (click)="addNewRow()"
      >
        + Key
      </ap-button>
    </ng-container>
  </div>

  <div
    class="lg:ap-max-h-[505px]"
    [ngClass]="{
      '!ap-max-h-[calc(100vh-162px)]': canAddNewField,
      'ap-overflow-y-auto': !canAddNewField
    }"
  >
    <form
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      class="ap-grid ap-grid-cols-2 ap-gap-4"
    >
      <div
        class="ap-flex ap-flex-row ap-gap-3 ap-justify-between ap-items-center ap-border-b ap-border-[#F2F4F7] ap-px-[30px] ap-py-[10px] group"
        *ngFor="let row of rows.controls; let i = index"
      >
        <div
          class="ap-flex ap-flex-col ap-justify-center ap-items-start ap-gap-2"
        >
          <p
            class="!ap-text-[#101828] !ap-text-sm !ap-font-medium !ap-font-inter"
          >
            {{ row.value.key }}
          </p>
          <div>
            <span
              *ngIf="!row.value.isEditMode"
              class="ap-break-all ap-overflow-x-auto !ap-text-[#667085] !ap-text-sm !ap-font-medium !ap-font-inter"
            >
              {{ row.value.value }}
            </span>

            <textarea
              *ngIf="row.value.isEditMode"
              [formControl]="getValueControl(row)"
              class="ap-w-full ap-border ap-border-[#D0D5DD] ap-resize-none ap-overflow-hidden ap-px-[14px] ap-py-[6px] ap-bg-white custom-textarea ap-rounded-md !ap-text-[#475467] !ap-text-sm !ap-font-medium !ap-font-inter"
              rows="1"
              (input)="autoResize($event)"
              [attr.data-index]="i"
              (change)="
                onValueChange(getValueControl(row).value, row.value.key)
              "
              (click)="
                triggerHighlightText(
                  getValueControl(row).value,
                  row.value.page_number,
                  form.value.rows
                )
              "
            ></textarea>
          </div>
        </div>

        <div
          class="ap-opacity-0 ap-transition-opacity ap-duration-300 svg-icon"
          [ngClass]="{ '!ap-opacity-100': row.value.isEditMode }"
        >
          <svg-icon
            class="ap-w-[20px] ap-h-[20px] ap-cursor-pointer"
            [applyClass]="true"
            [src]="
              row.value.isEditMode
                ? 'assets/img/newDesign/extractor/ok.svg'
                : 'assets/img/newDesign/extractor/edit.svg'
            "
            (click)="toggleEditMode($event, i)"
          >
          </svg-icon>
        </div>
      </div>
    </form>
  </div>
</div>
