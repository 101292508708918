<div class="ap-flex ap-flex-col ap-w-full ap-h-full">
  <div
    class="ap-sticky ap-top-0 ap-bg-white ap-z-50 ap-flex ap-flex-row ap-justify-between ap-items-center ap-h-11 ap-border-b ap-border-[#D0D5DD]"
  >
    <ng-container *ngIf="multipleFiles?.length">
      <mat-form-field class="ap-w-full" class="ap-max-w-full custom-no-border">
        <mat-select [(value)]="selectedFileName">
          <mat-option
            *ngFor="let option of multipleFiles"
            [value]="option.fileName"
            (click)="selectTheSelectedFile(option)"
            i18n
            >{{ option.fileName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </ng-container>

    <div
      class="ap-flex ap-flex-row ap-items-center ap-justify-center ap-gap-4 ap-ml-auto"
    >
      <ap-button
        *ngIf="fileUrl && (hasJsonButton || text)"
        btnColor="primary"
        [btnStyle]="
          currentViewing === 'pdf' || currentViewing === 'img'
            ? 'flat'
            : 'basic'
        "
        btnSize="small"
        (click)="changeViewMode('file')"
      >
        File
      </ap-button>

      <div
        *ngIf="fileUrl && (hasJsonButton || text)"
        class="ap-w-[1px] ap-h-4 ap-bg-[#ebebeb]"
      ></div>

      <ap-button
        *ngIf="text && (hasJsonButton || fileUrl)"
        btnColor="primary"
        [btnStyle]="currentViewing === 'text' ? 'flat' : 'basic'"
        btnSize="small"
        (click)="changeViewMode('text')"
      >
        Text
      </ap-button>

      <div
        *ngIf="text && (hasJsonButton || fileUrl)"
        class="ap-w-[1px] ap-h-4 ap-bg-[#ebebeb]"
      ></div>

      <ap-button
        *ngIf="hasJsonButton && (text || fileUrl)"
        btnColor="primary"
        [btnStyle]="currentViewing === 'json' ? 'flat' : 'basic'"
        btnSize="small"
        (click)="changeViewMode('json')"
      >
        JSON
      </ap-button>

      <div
        *ngIf="hasJsonButton && (text || fileUrl)"
        class="ap-w-[1px] ap-h-4 ap-bg-[#ebebeb]"
      ></div>

      <ap-icon-button
        [width]="22"
        [height]="22"
        iconUrl="assets/img/newDesign/forms/download.svg"
        (click)="downloadHandler($event)"
      ></ap-icon-button>
    </div>
  </div>

  <div class="ap-w-full ap-h-full ap-overflow-y-auto">
    <!-- PDF | Text | Image viewer -->
    <div
      class="ap-w-full ap-h-[calc(100%-48px)] ap-bg-[#E4E7EC] viewer-container"
      [ngClass]="{
        '!ap-h-0':
          currentViewing === 'json' ||
          currentViewing === 'pdf' ||
          selectedAttachmentType === 'FILE',
        'custom-full-screen': isFullScreen
      }"
    ></div>

    <pdf-viewer
      *ngIf="selectedAttachmentType === 'FILE'"
      [src]="fileUrl"
      [render-text]="true"
      [show-all]="true"
      [original-size]="false"
      [page]="currentPageIndex + 1"
      [fit-to-page]="true"
      [autoresize]="true"
      class="ap-w-full ap-h-[calc(100%-48px)] pdf-container"
    ></pdf-viewer>

    <!-- JSON viewer -->
    <ng-container *ngIf="currentViewing === 'json'">
      <div class="ap-w-full ap-h-[calc(100%-48px)] ap-overflow-auto">
        <ngx-json-viewer [json]="staticFormsData?.formData"></ngx-json-viewer>
      </div>
    </ng-container>

    <div
      class="ap-w-full ap-h-12 ap-bg-[#F2F4F7] ap-p-2 ap-flex ap-flex-row ap-items-center ap-justify-between"
    >
      <div class="ap-flex ap-flex-row ap-gap-[10px] ap-items-center">
        <ng-container *ngIf="iconSrc">
          <svg-icon
            class="ap-w-[24px] ap-h-[32px]"
            [applyClass]="true"
            [src]="iconSrc"
          >
          </svg-icon>
        </ng-container>
        <div
          *ngIf="
            currentViewing === 'text' ||
            currentViewing === 'pdf' ||
            selectedAttachmentType === 'FILE'
          "
          class="ap-flex ap-flex-row ap-justify-center ap-items-center ap-gap-1"
        >
          <svg-icon
            class="ap-w-[16px] ap-h-[16px] ap-cursor-pointer"
            [applyClass]="true"
            src="assets/img/newDesign/forms/left-arrow.svg"
            (click)="goToPreviousTextPage()"
          >
          </svg-icon>

          <p class="ap-text-[#232323] ap-text-sm ap-font-inter ap-font-normal">
            Page {{ currentPageIndex + 1 }}/{{ formPages.length }}
          </p>

          <svg-icon
            class="ap-w-[16px] ap-h-[16px] ap-cursor-pointer"
            [applyClass]="true"
            src="assets/img/newDesign/forms/right-arrow.svg"
            (click)="goToNextTextPage()"
          >
          </svg-icon>
        </div>
      </div>

      <svg-icon
        class="ap-w-[25px] ap-h-[25px] ap-cursor-pointer"
        [applyClass]="true"
        src="assets/img/newDesign/extractor/maximize.svg"
        (click)="isFullScreen = !isFullScreen"
      >
      </svg-icon>

      <ng-container *ngIf="isFullScreen">
        <div class="ap-fixed ap-top-5 ap-right-5 ap-z-[10000]">
          <ap-button
            btnColor="warn"
            btnStyle="flat"
            btnSize="small"
            (click)="isFullScreen = !isFullScreen"
            >Close</ap-button
          >
        </div>
      </ng-container>
    </div>
  </div>
</div>
