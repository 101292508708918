import { Static, Type } from '@sinclair/typebox'

export const CaseTypeTemplateCreationParamDto = Type.Object({
    projectId: Type.String(),
})

export type CaseTypeTemplateCreationParamDto = Static<typeof CaseTypeTemplateCreationParamDto>

export const CaseTypeTemplateCreationRequestBodyDto = Type.Object({
    caseType: Type.String(),
    caseFunctionName: Type.String(),
    caseIdentifierKeys: Type.Array(Type.String()),
})
export type CaseTypeTemplateCreationRequestBodyDto = Static<typeof CaseTypeTemplateCreationRequestBodyDto>


export const CaseTypeTemplatePartialUpdateRequestBodyDto = Type.Partial(CaseTypeTemplateCreationRequestBodyDto)
export type CaseTypeTemplatePartialUpdateRequestBodyDto = Static<typeof CaseTypeTemplatePartialUpdateRequestBodyDto>

export const CaseTypeTemplatePartialUpdateParamDto = Type.Object({
    projectId: Type.String(),
    caseTypeTemplateId: Type.String(),
})
export type CaseTypeTemplatePartialUpdateParamDto = Static<typeof CaseTypeTemplatePartialUpdateParamDto>


export const CaseTypeTemplateGetOneByIdParamDto = Type.Object({
    projectId: Type.String(),
    caseTypeTemplateId: Type.String(),
})
export type CaseTypeTemplateGetOneByIdParamDto = Static<typeof CaseTypeTemplateGetOneByIdParamDto>


export const CaseTypeTemplateGetAllParamDto = Type.Object({
    projectId: Type.String(),
})
export type CaseTypeTemplateGetAllParamDto = Static<typeof CaseTypeTemplateGetAllParamDto>