import { Static, Type } from '@sinclair/typebox'
import { BaseModelSchema } from '../../common'

export const CaseTypeTemplate = Type.Object({
    ...BaseModelSchema,
    projectId: Type.String(),
    caseType: Type.String(),
    caseFunctionId: Type.String(),
    caseFunctionName: Type.String(),
    caseIdentifierKeys: Type.Array(Type.String()),
})

export const CaseTypeTemplateCreationPayload = Type.Omit(
    CaseTypeTemplate,
    [
        'id',
        'created',
        'updated',
        'projectId',
        'caseFunctionId',
    ],
)


export const CaseTypeTemplateUpdatePayload = Type.Partial(CaseTypeTemplateCreationPayload)

export type CaseTypeTemplate = Static<typeof CaseTypeTemplate>
export type CaseTypeTemplateCreationPayload = Static<typeof CaseTypeTemplateCreationPayload>
export type CaseTypeTemplateUpdatePayload = Static<typeof CaseTypeTemplateUpdatePayload>