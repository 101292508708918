<div
  class="ap-text-3xl ap-font-semibold ap-mt-6 ap-font-inter ap-text-center"
  i18n
>
  Sign into your account
</div>
<div
  class="ap-text-sm ap-font-normal ap-mt-3 ap-font-inter ap-flex ap-flex-row ap-justify-center ap-items-center"
  i18n
>
  Don't have an account?
  <a
    class="ap-ml-1 ap-text-sm ap-font-semibold ap-text-[#175CD3] !ap-no-underline"
    routerLink="/sign-up"
    >Sign up</a
  >
</div>

<ap-third-party-auth></ap-third-party-auth>
<app-authentication-methods-separator></app-authentication-methods-separator>
<form
  *ngIf="loginsWithEmailEnabled$ | async"
  [formGroup]="loginForm"
  (ngSubmit)="signIn()"
  class="ap-flex ap-flex-col ap-gap-2 ap-mt-6 ap-font-inter custom-login"
>
  <div class="ap-text-sm ap-font-medium">Email</div>
  <mat-form-field class="ap-w-full ap-font-inter" appearance="outline">
    <input
      type="email"
      matInput
      placeholder="Enter your email"
      i18n-placeholder
      formControlName="email"
    />
    <mat-error *ngIf="loginForm.controls.email!.invalid">
      <ng-container
        *ngIf="loginForm.controls.email!.getError('email'); else emailRequired"
        i18n
      >
        Email is invalid
      </ng-container>
      <ng-template #emailRequired i18n>Email is required</ng-template>
    </mat-error>
  </mat-form-field>

  <div class="ap-text-sm ap-font-medium">Password</div>
  <mat-form-field class="ap-w-full" appearance="outline">
    <input
      type="password"
      matInput
      placeholder="Password"
      i18n-placeholder
      formControlName="password"
    />
    <mat-error *ngIf="loginForm.controls.password!.invalid" i18n
      >Password is required</mat-error
    >
  </mat-form-field>

  <div class="ap-flex ap-flex-row ap-justify-end">
    <a
      class="ap-ml-1 ap-text-sm ap-font-semibold ap-text-[#175CD3] !ap-no-underline"
      routerLink="/forgot-password"
      >Forgot password</a
    >
  </div>

  <div
    *ngIf="showResendVerification"
    class="ap-text-center ap-flex-col ap-flex ap-items-center ap-justify-center ap-gap-2"
  >
    Please check your inbox to verify your account.
    <ap-button
      type="button"
      btnSize="small"
      btnStyle="stroked"
      (buttonClicked)="sendVerificationEmail()"
      [loading]="sendingVerificationEmail"
      [darkLoadingSpinner]="true"
      >Resend verification
    </ap-button>
  </div>

  <div class="text-center ap-mt-2 ap-flex ap-flex-col ap-gap-2">
    <ap-button
      [loading]="loading"
      btnColor="primary"
      btnSize="large"
      [fullWidthOfContainer]="true"
      class="ap-text-lg ap-font-semibold"
      i18n
    >
      Sign in
    </ap-button>

    <div
      *ngIf="loading"
      class="ap-text-center ap-text-lg ap-font-normal ap-text-blue-600 ap-animate-pulse"
    >
      Please hold on while we prepare your environment.
    </div>
  </div>
  <mat-error
    *ngIf="showNotFoundMemberOrProjectOwnerMessage"
    class="ap-text-center"
    i18n
    >You are not the owner or a member of any project.</mat-error
  >
  <mat-error
    *ngIf="showInvalidEmailOrPasswordMessage"
    class="ap-text-center"
    i18n
    >Invalid email or password</mat-error
  >
  <mat-error class="ap-text-center" *ngIf="invitationOnlySignIn" i18n>
    You are not invited to any project, please contact your administrator.
  </mat-error>
  <mat-error class="ap-text-center" *ngIf="showDisabledUser" i18n>
    Your user is inactive, please contact your administrator to activate it.
  </mat-error>
  <mat-error class="ap-text-center" *ngIf="domainIsNotAllowed" i18n>
    Your email domain is not allowed to sign up, please contact your
    administrator.
  </mat-error>
</form>
<div
  class="ap-typography-caption ap-text-body ap-text-center ap-mt-5 ap-flex ap-gap-2 ap-justify-center ap-font-inter ap-w-10/12 ap-mx-auto"
>
  <span i18n
    >By signing in, I agree with the
    <a
      class="ap-text-sm ap-font-semibold ap-text-[#175CD3] !ap-no-underline"
      href="https://upbrains.ai/terms"
      target="_blank"
      >Terms</a
    >
    and
    <a
      class="ap-text-sm ap-font-semibold ap-text-[#175CD3] !ap-no-underline"
      href="https://upbrains.ai/privacypolicy"
      target="_blank"
      >Privacy Policy</a
    ></span
  >
</div>

<ng-container *ngIf="authenticate$ | async"></ng-container>
<ng-container *ngIf="sendVerificationEmail$ | async"></ng-container>
