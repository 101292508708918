import { Static, Type } from '@sinclair/typebox'
import { PaginatedResult } from '../../search-engine'
import { CaseIdentifier } from './case-identifiers'


export const CaseItem = Type.Object({
    projectId: Type.String(),
    caseId: Type.String(),
    teamId: Type.String(),
    caseName: Type.String(),
    creationTimestamp: Type.Date(),
    inboxId: Type.String(),
    lastUpdateTimestamp: Type.Date(),
    
    identifiers: CaseIdentifier,
})

export const PaginatedCaseItems = PaginatedResult(CaseItem)

export type CaseItem = Static<typeof CaseItem>

export type PaginatedCaseItems = Static<typeof PaginatedCaseItems>