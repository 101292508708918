import { Static, Type } from '@sinclair/typebox'


export enum SimpleFilterOperations {
    AND = 'AND',
    OR = 'OR',
}

export enum SimpleFilterComparisonTypes {
    EQUAL = 'EQUAL',
    NOT_EQUAL = 'NOT_EQUAL',
}

export const SearchEngineSimpleFilterItem = Type.Object({
    fieldName: Type.String(),
    fieldValue: Type.Unknown(),
    comparisonType: Type.Enum(SimpleFilterComparisonTypes),
})

export type SearchEngineSimpleFilterItem = Static<typeof SearchEngineSimpleFilterItem>

export const SearchEngineSimpleFilters = Type.Object({
    filterOperation: Type.Enum(SimpleFilterOperations),
    filteringItems: Type.Array(SearchEngineSimpleFilterItem),
})

export type SearchEngineSimpleFilters = Static<typeof SearchEngineSimpleFilters>